import './App.css';
import CV from "./CV";
import 'antd/dist/antd.css'
import Icon, {
    BankOutlined,
    CodeOutlined,
    ExperimentOutlined,
    ProfileOutlined,
    RocketOutlined,
    SmileOutlined, StarOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import { Rate } from "antd";

function App() {
    return (
        <CV
            personalData={{
                name: 'Claire V. Hammond',
                title: [
                    'Doctoral Student at Rice University',
                    'Clinical Biomechanist at Stanford',
                ],
                image: '/headshot128hd.jpg',
                contacts: [
                    { type: 'email', value: 'me@cvhammond.com' },
                    // { type: 'phone', value: '+00 (123) 456 78 90' },
                    { type: 'location', value: 'Mountain View, CA, USA' },
                    { type: 'linkedin', value: 'cvhammond' },
                    { type: 'twitter', value: 'cvhammond_' },
                    { type: 'github', value: 'cvhammond' },
                    // {type: 'reddit', value: 'CVHammond'}
                ]
            }}
            sections={[
                {
                    type: 'text',
                    title: 'Profile',
                    // content: 'The way we move informs our pain and our dysfunction. My work aims to contribute to the quantification of the human body in motion and the use of that information to improve treatment outcomes.',
                    content: 'I am a PhD student in the Rice Computational Neuromechanics Lab. My research focuses on the development of tools to improve the accuracy of musculoskeletal modeling and simulation. My goal is to help improve clinical outcomes through biomechanical modeling and simulation.',
                    icon: <UserOutlined />
                },
                {
                    type: 'common-list',
                    title: 'Experience',
                    // description: 'Optional',
                    icon: <ProfileOutlined />,
                    items: [
                        {
                            title: 'Clinical Biomechanist',
                            authority: 'Stanford Children\'s Health',
                            description: 'Leading data collection and analysis of children and adults with altered gait',
                            authorityWebSite: 'https://stanfordchildrens.org',
                            authorityMeta: 'Clinical Gait Lab',
                            rightSide: 'Sep. 2023 - Present',
                        },
                        // {
                        //     title: 'Developer & Technology Lead',
                        //     authority: 'Roxie Health',
                        //     description: 'Developing the future of digital care assessments for aging adults',
                        //     authorityWebSite: 'https://roxiehealth.com',
                        //     authorityMeta: 'Mass Challenge Startup',
                        //     rightSide: 'Jul. 2022 - Present',
                        // },
                        {
                            title: 'Graduate Student',
                            authority: 'Rice Computational Neuromechanics Lab',
                            description: 'Leading development of an NIH funded project for the next generation of OpenSim tools',
                            authorityWebSite: 'https://rcnl.rice.com',
                            authorityMeta: 'NIH R01 EB030520',
                            rightSide: 'Aug. 2021 - Present',
                            // descriptionTags: ['OpenSim', 'MATLAB']
                        },
                        {
                            title: 'Biomechanist/Tech Lead',
                            authority: 'Movement Health Science Inc',
                            description: 'Developing data-driven technology to improve human performance',
                            authorityWebSite: 'https://movementhealthscience.com',
                            authorityMeta: 'Funded Startup',
                            rightSide: 'Aug. 2020 - Jul. 2021'
                        },
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Education',
                    icon: <BankOutlined />,
                    items: [
                        {
                            title: 'Mechanical Engineering (PhD)',
                            authority: 'Rice University',
                            authorityMeta: 'Rice Computational Neuromechanics Lab',
                            authorityWebSite: 'https://www.rice.edu',
                            rightSide: '2021 - Present'
                        },
                        {
                            title: 'Computer Science (MS)',
                            authority: 'Georgia Institute of Technology',
                            authorityMeta: 'Computational Perception & Robotics',
                            authorityWebSite: 'https://www.gatech.edu',
                            rightSide: '2017-2019'
                        },
                        {
                            title: 'Mechanical Engineering (Honors BEng)',
                            authority: 'University of Delaware',
                            authorityMeta: 'Minor in Computer Science',
                            authorityWebSite: 'https://www.udel.edu',
                            rightSide: '2013-2017'
                        }
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Conference Presentations',
                    icon: <TeamOutlined />,
                    items: [
                        {
                            title: 'Stroke Grand Challenge Competition and NMSM Pipeline Training Workshop',
                            authority: 'American Society of Biomechanics',
                            authorityWebSite: 'https://asbweb.org/asb-2023/',
                            authorityMeta: 'Knoxville, TN, USA',
                            rightSide: 'Aug. 2023'
                        },
                        {
                            title: 'Easy-to-use MATLAB Software for Personalizing OpenSim Neuromusculoskeletal Models',
                            authority: 'International Society of Biomechanics / Japanese Society of Biomechanics',
                            authorityWebSite: 'https://www.isb-jsb2023.com/',
                            authorityMeta: 'Fukuoka, Japan',
                            rightSide: 'Jul. 2023'
                        },
                        {
                            title: 'Personalization of Joint Parameters Through Optimization Decreases Inverse Kinematics Marker Distance Errors',
                            authority: 'World Congress of Biomechanics',
                            authorityWebSite: 'https://www.wcb2022.com/',
                            authorityMeta: 'Taipei, Taiwan',
                            rightSide: 'Jul. 2022'
                        },
                        {
                            title: 'Peer Reviewer',
                            authority: 'Journal of Open Source Software',
                            // description: ,
                            authorityWebSite: 'https://joss.theoj.org/',
                            // authorityMeta: '',
                        },
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Projects',
                    icon: <ExperimentOutlined />,
                    items: [
                        {
                            title: 'Neuromusculoskeletal Modeling (NMSM) Pipeline',
                            authority: 'Rice Computational Neuromechanics Lab',
                            description: 'First author (publ. pending) of pipeline to facilitate neuromusculoskeletal modeling research',
                            authorityWebSite: 'https://nmsm.rice.edu/',
                            rightSide: 'Aug. 2023'
                        },
                        {
                            title: 'Biomechanics Zone',
                            authority: 'Self-Directed',
                            description: 'Developed a website for biomechanics education and research',
                            authorityWebSite: 'https://biomechanics.zone/',
                            rightSide: 'Aug. 2021'
                        },
                    ]
                },
                {
                    type: 'tag-list',
                    title: 'Skills',
                    icon: <RocketOutlined />,
                    items: [
                        'OpenSim',
                        // 'MATLAB',
                        // 'React',
                        // 'Python',
                        'Multi-body Dynamics',
                        'Software Engineering',
                        'Simulation & Modeling',
                        'Computer Vision',
                    ]
                },
                {
                    type: 'common-list',
                    title: 'Languages',
                    icon: <CodeOutlined />,
                    items: [
                        {
                            title: 'MATLAB',
                            authority: 'Professional',
                            rightSide: <Rate value={5} />
                        },
                        {
                            title: 'Rust',
                            authority: 'Strong',
                            rightSide: <Rate value={4} />
                        },
                        {
                            title: 'Python',
                            authority: 'Proficient',
                            rightSide: <Rate value={3} />
                        },
                    ]
                },
                {
                    type: 'tag-list',
                    title: 'Hobbies',
                    icon: <SmileOutlined />,
                    items: [
                        'Gravel Cycling',
                        'Coffee',
                        'My Cat',
                    ]
                },
            ]}
        />
    )
}

export default App;
